import ApiService from './ApiService';
// import Repository from './Repository'

class PageService extends ApiService {
    resource = '/page';
    newBituChatApi = process.env.VUE_APP_NEW_BITU_API;
    getListPages(params) {
        return this.getArr(`${this.resource}/list`, params);
    }

    syncFbPage(params) {
        return this.post(`${this.resource}/sync`, params);
    }

    syncFbPageNew(params) {
        return this.axiosInstance.post(`${this.newBituChatApi}/api/v1/page/sync`, params);
    }

    updatePage(id, params) {
        return this.post(`${this.resource}/${id}/update`, params);
    }

    syncOldData(id, params) {
        return this.post(`${this.resource}/${id}/sync-old-data`, params);
    }

    getHideAllPage() {
        return this.get(`/setting`)
    }
    
    hideAllPage(params) {
        return this.post(`/page/on-off-hide-comment`, params)
    }

    getListPageAssigneds(params) {
        return this.getArr(`${this.resource}/list/assigned`, params);
    }

    hideCommmentOfPage(params) {
        return this.post(`/page/on-off/hide-comment-a-page`, params)
    }

    getHideOfPage(params) {
        return this.get(`/page/on-off/get-hide-comment-a-page`, params)
    }
}

const pageService = new PageService;
export { pageService };
export default PageService;
